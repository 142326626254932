import styled from '@emotion/styled';
import ShoppingCart from '@gatsbystorefront/gatsby-theme-storefront-shopify/src/components/Icons/ShoppingCart';
import Search from '../components/Search';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyLink from 'gatsby-link';
import React, { useState } from 'react';
import { Box, Flex, Text, Image } from 'rebass';
import '../templates/main/carousel.css';
import Menu from './Menu';
import strings from './strings.json';
import loadable from '@loadable/component';
import { useThemeUI } from 'theme-ui';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const { ariaShoppingCartLabel, ariaHomaPageLinkLabel } = strings;

// const { theme } = useThemeUI();

const NavbarBase = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
`;

const Nav = styled(NavbarBase)`
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transition: all 200ms ${(props) => (props.show ? 'ease-in' : 'ease-out')};
  transform: ${(props) => (props.show ? 'none' : 'translate(0, -100%)')};
`;

const Navbar = (props) => {
  const [hideNavbarOnScroll, setHideNavbarOnScroll] = useState(true);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      // Note: prevPos.y > -12 is here to fix Nav component disappearing bug
      // due to elastic scrolling/bounce effect in mobile Safari.
      const isShow = currPos.y > prevPos.y || prevPos.y > -12;
      if (isShow !== hideNavbarOnScroll) setHideNavbarOnScroll(isShow);
    },
    [hideNavbarOnScroll],
    null,
    false,
    100
  );

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          gatsbyStorefrontConfig {
            storeName
            menu {
              handle
              id
              link
              name
              parentId
              type
            }
          }
        }
      }
    }
  `);

  const { storeName, menu } = data.site.siteMetadata.gatsbyStorefrontConfig;

  const menuBackgroundColor = '#e9c3db';

  return (
    <Nav show={hideNavbarOnScroll}>
      <Box py={3} width={1} as="nav" bg="white">
        <Flex
          style={{ maxWidth: 1300 }}
          justifyContent="center"
          alignItems="center"
          mx="auto"
          px={[3, null, 4]}
        >
          <Box width={100}>
            <Menu menu={menu} />
          </Box>

          <Text
            as={GatsbyLink}
            to="/"
            aria-label={ariaHomaPageLinkLabel}
            style={{
              textAlign: 'center',
              fontFamily: 'Great Vibes',
              color: '#d9b8b4',
              textDecoration: 'none',
            }}
            ml="auto"
            fontSize={['15px', '30px']}
          >
            Yana's Nails And Beauty
          </Text>

          <Flex ml="auto" width={['100%', 100]}>
            <Box ml="auto">
              <Search width="25px" height="25px" />
            </Box>

            <Text
              as={GatsbyLink}
              aria-label={ariaShoppingCartLabel}
              to="/cart"
              style={{ textDecoration: 'none' }}
              ml="auto"
            >
              <ShoppingCartIcon
                style={{ width: 25, height: 25, color: '#d9b8b4' }}
              />
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Nav>
  );
};

export default React.memo(Navbar);
