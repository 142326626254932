/** @jsx jsx */
/* eslint no-unused-vars: 0 */
import loadable from '@loadable/component';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyLink from 'gatsby-link';
import React from 'react';
import { Box, Flex, Link, Text } from 'rebass';
import { jsx, useThemeUI } from 'theme-ui';
import '../templates/main/carousel.css';

const SocialIcon = loadable(() => import('../components/SocialIcon'));

const validURL = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

function Footer() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          gatsbyStorefrontConfig {
            email
            company
            location
            address
            phone
            workingDays
            workingHours
            socialNetworks
            footerLinks {
              name
              link
            }
          }
        }
      }
    }
  `);

  const {
    email,
    company,
    location,
    address,
    phone,
    workingDays,
    workingHours,
    socialNetworks,
    footerLinks,
  } = data.site.siteMetadata.gatsbyStorefrontConfig;

  const { theme } = useThemeUI();

  const year = new Date().getFullYear();
  const menuBackgroundColor = '#e9c3db';

  return (
    <Box>
      <Box
        as="footer"
        sx={{ maxWidth: 1300, width: '100%', height: '100%' }}
        mx="auto"
        px={2}
        mt={3}
      >
        <Flex sx={{ flexWrap: 'wrap', alignItems: 'center' }}>
          <Flex
            sx={{
              justifyContent: ['center'],
              flexWrap: 'wrap',
              width: '100%',
              fontSize: [1, 3],
            }}
          >
            {footerLinks
              ? footerLinks.map((link, index) => {
                  // If link is valid url use <a>
                  // else use gatsby-link
                  if (validURL(link.link)) {
                    return (
                      <Text
                        color="#d9b8b4"
                        style={{ fontFamily: 'Great Vibes' }}
                        key={index}
                        mr={[3, 0]}
                      >
                        <Link style={{ color: '#d9b8b4' }} href={link.link}>
                          {link.name}
                        </Link>
                      </Text>
                    );
                  } else {
                    return (
                      <Text
                        style={{ fontFamily: 'Great Vibes' }}
                        key={index}
                        color="#d9b8b4"
                        mr={[3, 0]}
                      >
                        <GatsbyLink
                          style={{ color: '#d9b8b4', fontWeight: 'bold' }}
                          to={link.link}
                          sx={theme.variants.link}
                        >
                          {link.name}
                        </GatsbyLink>
                      </Text>
                    );
                  }
                })
              : ''}
          </Flex>
        </Flex>
        <Flex sx={{ justifyContent: 'center' }}>
          {socialNetworks
            ? socialNetworks.map((socialNetwork, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      // marginLeft: [0, 3],
                      opacity: 0.8,
                      '&:hover,&:focus,&:active': {
                        opacity: 1,
                      },
                    }}
                  >
                    <SocialIcon
                      url={socialNetwork}
                      // bgColor={menuBackgroundColor}
                    />
                  </Box>
                );
              })
            : ''}
        </Flex>

        <Flex sx={{ justifyContent: 'center' }}>
          <Box>
            <Text
              sx={{
                fontSize: [1],
                fontFamily: 'Great Vibes',
                color: '#d9b8b4',
                fontWeight: 'bold',
              }}
            >
              © {year} {company || ''}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

export default React.memo(Footer);
